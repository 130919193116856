import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between mt-6 cursor-pointer" }
const _hoisted_2 = { class: "flex justify-center mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cssClass)
  }, [
    _createElementVNode("div", null, _toDisplayString(_ctx.$t("type_of_match_and_level")), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 br60", { selected: _ctx.competitive }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click($event, true)))
      }, _toDisplayString(_ctx.$t("competitive")), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60", { selected: !_ctx.competitive }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.click($event, false)))
      }, _toDisplayString(_ctx.$t("friendly")), 3)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProgressBar, {
        firstValue: _ctx.initLevel,
        lastValue: _ctx.endLevel,
        value: _ctx.endLevel,
        showAllLevels: false
      }, null, 8, ["firstValue", "lastValue", "value"])
    ])
  ], 2))
}