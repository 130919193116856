import { Booking } from "@/custom-models/booking";
import { Format } from "@/helpers/formatHelper";
import { NO_LEVEL, Origin, PadbolSize, PadelSize, PickleballSize, PlayersNumber, RacquetballSize, Sport, SquashSize, TenisSize } from "@/enum/constants";
import { BookingApi } from "@/service/BookingApi";
import { AxiosResponse } from "axios";
import { Participant, PricesRequest } from "models";
import { PlayerType } from "@/enum/constants";
import { Court, CourtPrice, ResourceResponse } from "@/custom-models/mergeResources"

export class BookingHelper {

    static parse(apiBooking: any): Booking {
        const res: Booking = {
            date: apiBooking.date,
            duration: apiBooking.duration,
            id: apiBooking.id,
            tenant: apiBooking.tenant?.name,
            players: apiBooking.players,
            tenantId: apiBooking.tenant?.id,
            cordX: apiBooking.tenant?.cordX,
            cordY: apiBooking.tenant?.cordY,
            resourceId: apiBooking.bookingBlocks[0].resourceId,
            size: apiBooking.bookingBlocks[0].attributes.size,
            sport: apiBooking.bookingBlocks[0].sport,
            bookingBlocks: apiBooking.bookingBlocks[0],
            results: apiBooking.results,
            type: apiBooking.type,
            price: apiBooking.placeAmount!,
            imageUrl: apiBooking.tenant?.gallery,
            status: apiBooking.status,
            canBeCancelled: apiBooking.canBeCancelled,
            gender: apiBooking.gender,
            minLevel: apiBooking.minLevel,
            maxLevel: apiBooking.maxLevel,
            paymentType: apiBooking.paymentType,
            allowedPaymentMethods:apiBooking.tenant.allowedPaymentMethods,
            openFacilityUrl: apiBooking.openFacilityUrl,
            levelProvider: apiBooking.levelProvider,
            competitive: apiBooking.competitive
        };
        return res;
    }

    static getOrigin() {
        const userAgent: any = window;
        if (userAgent.webkit && userAgent.webkit.messageHandlers) {
            // its running in a webview
            return Origin.IOS;
        }

        return Origin.WEB;
    }

    static async getCustomersBooking(start: number, size: number, owner:boolean, include:string) {
      const response = await BookingApi.getCustomersBooking(start, size, owner, include )
      return this.checkResponse(response);
    }
    
    static checkResponse(response:AxiosResponse) {
      if(!Format.IsNull(response.data)){
        return response.data
      }
      return null;
    }

    static async cancelBooking(id: string) {
      return await BookingApi.cancelBooking(id);
    }

    static async copyBoookingUrlToClipBoard(id: string) {
      navigator.clipboard.writeText(getBookingUrl(id));
      return true;
    }

    static async messageAvailability(request: any) {
      const response = await BookingApi.availability(request)
      if(!Format.IsNull(response.data.message)){
        return response.data.message
      }
      return null;
    }

    static async getPrices(request: any) {
      const response = await BookingApi.getPrices(request)
      return this.checkResponse(response);
    }

}

export function getBookingUrl(id: string) {
  return document.location.origin + "/booking-join?b=" + id;
}

export function hasLevel(player: Participant) : boolean {
  if(player.type?.code !== PlayerType.EMPTY &&
     player.level !== undefined &&
     player.level > NO_LEVEL)
     {
       return  true;
  }
  return false
}

export function mergeResources(resources: any) {
  const courts = transformData(resources);
  return mergeCourts(courts);
}

export function transformData(courtsData: ResourceResponse[]): Court[] {
  return courtsData.map(court => ({
    id: court.id,
    name: court.name,
    attributes: court.attributes,
    preferredDuration: court.timeTable[0]?.preferedDuration || 0,
    courtPrices: court.timeTable[0]?.pricesAndDurations.map(priceData => ({
      duration: priceData.duration,
      rawPrice: priceData.price.grossAmount,
      grossAmountStr: priceData.price.grossAmountStr,
      locale: priceData.price.locale,
      netAmount: priceData.price.netAmount,
      taxes: priceData.price.taxes,
      taxesStr: priceData.price.taxesStr,
      currencyCode: priceData.price.currencyCode,
      allowedPaymentMethods: priceData.allowedPaymentMethods,
    })) || [],
  }));
}
export function returnSportPlayers(size: number){
   const individualSizes = [
    PadelSize.INDIVIDUAL,
    TenisSize.INDIVIDUAL,
    PickleballSize.INDIVIDUAL,
    PadbolSize.INDIVIDUAL,
    RacquetballSize.INDIVIDUAL,
    SquashSize.INDIVIDUAL,
  ];

  if (individualSizes.includes(size)) {
    return PlayersNumber.INDIVIDUAL;
  }

  return PlayersNumber.DOUBLE;
}
///////////private functions
function mergeCourts(courtsData: Court[]) {
  const groupedCourts = new Map<string, Court[]>();

  courtsData.forEach(court => {
    const key = `${court.attributes.type}-${court.attributes.wall}-${court.attributes.size}`;
    if (!groupedCourts.has(key)) {
      groupedCourts.set(key, []);
    }
    groupedCourts.get(key)?.push(court);
  });

  const merged = Array.from(groupedCourts.values()).map(courtList => {
    const priceMap = new Map<number, CourtPrice>();

    courtList.flatMap(court => court.courtPrices).forEach(price => {
      const key = price.duration;
      if (!priceMap.has(key) || price.rawPrice > priceMap.get(key)!.rawPrice) {
        priceMap.set(key, price);
      }
    });

    const uniquePricesList = Array.from(priceMap.values()).sort((a, b) => {
      if (a.duration !== b.duration) {
        return a.duration - b.duration;
      } else {
        return b.rawPrice - a.rawPrice;
      }
    });

    return {
      id: courtList[0].id,
      name: courtList.map(court => court.name).join(', '),
      attributes: courtList[0].attributes,
      preferredDuration: courtList[0].preferredDuration,
      courtPrices: uniquePricesList,
    };
  });

  return merged;
}