import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8992574a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-10" }
const _hoisted_2 = {
  key: 0,
  class: "pb-4"
}
const _hoisted_3 = { class: "popUpWrapper" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "cursor-pointer" }
const _hoisted_6 = { class: "pb-2 noline w-full" }
const _hoisted_7 = { class: "dropdown" }
const _hoisted_8 = { class: "flex pb-3" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "openingOpacityText text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgContainer = _resolveComponent("SvgContainer")!
  const _component_ResourceLabel = _resolveComponent("ResourceLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showTitle || _ctx.showTitle === undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getBeautifyDate()), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mergedCourts, (court, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([[{ selected: court.id === _ctx.resourceSelectedId }], "text-left"]),
          onClick: ($event: any) => (
          _ctx.resourceSelectedId != court.id
            ? (_ctx.resourceSelectedId = court.id)
            : (_ctx.resourceSelectedId = null),
            _ctx.selectedCourt = court
        )
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createVNode(_component_SvgContainer, { name: "arrowDownSmallIcon" }),
                _createVNode(_component_ResourceLabel, {
                  sport: _ctx.sport,
                  data: court,
                  showName: !_ctx.groupResources,
                  isBooking: _ctx.isBooking
                }, null, 8, ["sport", "data", "showName", "isBooking"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(court.courtPrices, (option, optionIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: optionIndex,
                  onClick: ($event: any) => (_ctx.selectDuration($event, option.duration)),
                  class: _normalizeClass([{
                        selected: option.duration === _ctx.durationSelected && court.id === _ctx.resourceOptionSelectedId,
                        active: option.duration === _ctx.durationPreferred
                      }, "optionButton md:p-4 sm:p-4 relative cursor-pointer"])
                }, [
                  _createElementVNode("div", null, _toDisplayString(option.grossAmountStr), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(option.duration + " " + _ctx.$t("min")), 1)
                ], 10, _hoisted_9))
              }), 128))
            ])
          ])
        ], 10, _hoisted_4))
      }), 128))
    ]),
    (
        _ctx.checkoutOnDurationSelected === false ||
        _ctx.checkoutOnDurationSelected === undefined
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "search-btn flex justify-center items-center m-6 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkout()))
        }, _toDisplayString(_ctx.$t("text_continue")), 1))
      : _createCommentVNode("", true)
  ]))
}